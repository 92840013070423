import Vue from "vue";

export default {
  namespaced: true,

  state: () => ({
    data: {},
    pending: false,
    questsLang: null,
  }),

  getters: {
    data(state) {
      return state.data;
    },

    quests(state) {
      return state.data.results;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },

    SET_QUESTS_LANG(state, lang) {
      state.questsLang = lang;
    },

    SET_PENDING(state, status) {
      state.pending = status;
    },

    SET_ERRORS(state, data) {
      state.errors = data;
    },
  },

  actions: {
    async fetch({ commit }, lang = "all") {
      try {
        commit("SET_PENDING", true);

        const query =
          lang !== "all"
            ? new URLSearchParams({ available_language: lang }).toString()
            : "";

        const response = await Vue.axios.get(
          `/quests/?quest_course_isnull=true&${query}`
        );

        commit("SET_QUESTS_LANG", lang);

        if (response.status === 200) {
          commit("SET_DATA", response.data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
