import Vue from "vue";

export default {
  namespaced: true,

  state: () => ({
    data: {},
    pending: false,
  }),

  getters: {
    data(state) {
      return state.data;
    },

    lessons(state) {
      return state.data.results;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },

    SET_PENDING(state, status) {
      state.pending = status;
    },

    SET_ERRORS(state, data) {
      state.errors = data;
    },
  },

  actions: {
    async fetch({ commit }, courseId) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.get(`/quests/${courseId}/`);

        if (response.status === 200) {
          commit("SET_DATA", response.data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
