<template>
  <main class="main">
    <h1 class="title">
      {{ bnName }}
    </h1>
    <NavigatorContainer />
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import NavigatorContainer from "@/containers/Navigator/NavigatorContainer.vue";

export default {
  name: "Navigator",
  components: {
    NavigatorContainer,
  },

  computed: {
    ...mapGetters({
      bnName: "user/bnName",
    }),
  }
};
</script>
