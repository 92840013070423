<template>
  <a
    class="langSwitcher"
    :class="{ active: lang === $i18n.locale }"
    v-bind="{ ...$props, ...$attrs }"
    @click="switchLang"
  >
    <slot />
  </a>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "BaseLocaleSwitcher",
  props: {
    lang: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      coursesCurrentPage: "leadershipCourses/currentPage",
    }),
  },

  methods: {
    ...mapActions({
      fetchCourses: "navigatorCourses/fetch",
      fetchQuests: "navigatorQuests/fetch",
      navigatorCourseQuests: "navigatorCourseQuests/fetch",
    }),

    switchLang() {
      this.$i18n.locale = this.lang;
      this.$store.commit("CHANGE_LANG", this.lang);
      this.$router.push({ params: { lang: this.lang } });
      this.$store.dispatch("user/fetch");
      if (this.$route.name === "Assignment") {
        this.$store.dispatch("assignment/fetch", { page: 1 });
        this.$store.dispatch("assignmentStatuses/fetch");
        this.$store.dispatch("assignmentSources/fetch");
      } else if (this.$route.name === "Leads") {
        this.$store.dispatch("leadsStatuses/fetch");
      } else if (this.$route.name.startsWith("Updated")) {
        this.$store.dispatch("updatedOrderStatuses/fetch");
        this.$store.dispatch("updatedLeadsStatuses/fetch");
        this.$store.dispatch("updatedFilterFunnels/fetch");
        this.$store.dispatch("updatedTags/fetch");
      } else if (this.$route.name.includes("Tasks")) {
        const pathName = this.$route.path;
        const pathNameArr = pathName.split("/");
        const pathNameLast = pathNameArr[pathNameArr.length - 1];
        if (pathNameLast === "all") {
          this.$store.dispatch("leadTasks/fetchAll", { page: 1 });
        } else {
          this.$store.dispatch("leadTasks/fetch", {
            page: 1,
            filter: pathNameLast,
          });
        }
        this.$store.dispatch("leadTasks/fetchContactProgresses");
      } else if (this.$route.name === "Сontacts") {
        this.$store.dispatch("leadTasks/fetchContactProgresses");
        this.$store.dispatch("contacts/fetchContactHeat");
        this.$store.dispatch("contacts/fetchContactTop");
        this.$store.dispatch("contacts/fetch");
      }
      if (this.$route.name.includes("ContactsProfile")) {
        this.$store.dispatch("leadTasks/fetchContactProgresses");
        this.$store.dispatch("contacts/fetchContactHeat");
        this.$store.dispatch("contacts/fetchContactTop");
      }
      if (
        ["ContactsProfileEvents", "ContactsProfileStatus"].includes(
          this.$route.name
        )
      ) {
        this.$store.dispatch("contactsProfileEvents/fetch", {
          page: 1,
          contact: this.$route.params.profileId,
        });
      }
      if (this.$route.name.includes("ContactsProfileMeetings")) {
        this.$store.dispatch("contactsProfileMeetings/fetchMeetingTypes");
      }

      if (this.$route.name === "Courses") {
        this.$store.dispatch("leadershipCourses/fetch", { page: this.coursesCurrentPage });
      }

      if (this.$route.name === "Programs") {
        this.$store.dispatch("leadershipPrograms/fetch");
      }

      if (this.$route.name === "Navigator") {
        this.fetchCourses();
        this.fetchQuests();
      }

      if (this.$route.name === "NavigatorCourse") {
        const courseId = this.$route.params.courseId;
        this.navigatorCourseQuests({ courseId });
      }
    },
  },
};
</script>

<style lang="stylus">
.langSwitcher {
  cursor: pointer;
  display: inline-block;

  &.active {
    background: yellow;
  }
}
</style>
