<template>
  <li
    :class="[
      { available: isAvailableAtDate && isAvailableByPrevious },
      { done: lesson.isDone },
    ]"
    class="lesson"
  >
    <BaseLink
      :to="lesson.link"
      class="lesson__link"
      v-if="(isAvailableAtDate && isAvailableByPrevious) || lesson.isDone"
    />
    <h2 class="lesson__title">{{ lesson.title }}</h2>
    <div class="lesson__box">
      <template v-if="lesson.isDone">
        <span class="lesson__isDoneDate" v-if="lesson.isDoneDate">
          {{ lesson.isDoneDate }}
        </span>
        <div class="lesson__awardWrap" v-if="lesson.diamonds || lesson.gold">
          <div class="lesson__awardBox">
            <div
              class="lesson__awardPoints lesson__awardPoints_diamonds"
              v-if="lesson.diamonds"
            >
              <span>10</span>
              <img src="@/assets/images/diamond@2x.png" />
            </div>
            <div
              class="lesson__awardPoints lesson__awardPoints_money"
              v-if="lesson.gold"
            >
              <span>{{ lesson.gold }}</span>
              <img src="@/assets/images/gold@2x.png" />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <span
          class="lesson__deadline"
          v-if="lesson.deadline && isAvailableAtDate && isAvailableByPrevious"
        >
          <Tr>Дедлайн</Tr>
          <span class="lesson__deadlineDate">{{ deadlineDateFormatted }}</span>
        </span>
        <div
          class="lesson__availableBox"
          :class="{ lesson__availableBox_unavailable: !isAvailableAtDate }"
          v-if="this.lesson.availableDate && !isAvailableByPrevious"
        >
          <Tr>Будет доступно</Tr>
          <span class="lesson__isDoneDate lesson__availableDate">
            {{ availableDateFormatted }}
          </span>
        </div>
      </template>
    </div>
  </li>
</template>

<script>
export default {
  name: "NavigatorQuestLesson",
  props: {
    lesson: {
      type: Object,
      required: true,
    },
    lessons: {
      type: Array,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isAvailableByPrevious() {
      if (this.index === 0) {
        return true;
      } else if (
        this.index > 0 &&
        this.lessons[this.index - 1].isDone === true
      ) {
        return true;
      }
      return false;
    },
    isAvailableAtDate() {
      let nowDate = new Date().toLocaleString("fr-Ca").split(", ")[0];
      nowDate = nowDate.replace(/\./g, "-");
      const nowTime = new Date().toLocaleString().split(", ")[1].slice(0, 5);
      const now = `${nowDate} ${nowTime}`;
      if (
        this.lesson.availableDate &&
        new Date(now) > new Date(this.lesson.availableDate)
      ) {
        return true;
      }
      return false;
    },
    availableDateFormatted() {
      if (this.lesson.availableDate) {
        const formatDate = new Date(this.lesson.availableDate).toLocaleString(
          this.$i18n.locale,
          {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          }
        );
        return formatDate;
      }
      return false;
    },
    deadlineDateFormatted() {
      if (this.lesson.deadline) {
        const formatDate = new Date(this.lesson.deadline).toLocaleString(
          this.$i18n.locale,
          {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          }
        );
        return formatDate;
      }
      return false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.lesson {
  z-index: 1;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-left: 44px;
  max-width: calc(100vw - 16px);
  border-radius: 4px;
  box-shadow: 0px 3px 10px #0f164214;

  @media (min-width: tablet_large) {
    padding: 10px 12px;
    padding-left: 44px;
  }
}

.lesson::before {
  position: absolute;
  counter-increment: list;
  content: counter(list);
  left: 0;
  width: 32px;
  height: 100%;
  border-right: 2px solid #e6e7eb;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  font_bold();
  line-height: 40px;
  color: #0f1642;
  text-align: center;
}

.lesson__link {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.lesson__title {
  max-height: 20px;
  font_bold();
  line-height: 20px;
  letter-spacing: -0.1px;
  opacity: 0.3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: tablet_large) {
    max-width: 70%;
  }
}

.lesson__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (min-width: tablet_large) {
    width: 189px;
  }
}

.lesson__isDoneDate, .lesson__deadline, .lesson__availableBox {
  font(main_text_color, 12px, 400, 15px);
}

.lesson__isDoneDate {
  letter-spacing: -0.17px;
}

.lesson__deadline {
  letter-spacing: -0.17px;
}

.lesson__deadlineDate {
  // display: block;
  font-weight: 700;
}

.available::before {
  border: none;
  background-color: #ff9f43;
}

.done::before {
  border: none;
  background-color: #19c564;
  color: #fff;
}

.done .lesson__title, .done .lesson__box, .available .lesson__title, .available .lesson__box {
  opacity: 1;
}

.done .lesson__isDoneDate, .done .lesson__awardWrap {
  display: block;
}

.done .lesson__isDoneDate, .done .lesson__awardWrap {
  display: block;
}

.available .lesson__deadline {
  display: block;
}

.lesson__awardBox {
  display: inline-grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
}

.lesson__awardPoints_diamonds {
  order: 2;
}

.lesson__awardPoints_diamonds img {
  width: 20px;
  height: 16px;
}

.lesson__awardPoints_money img {
  width: 15px;
  height: 20px;
}

.lesson__awardPoints span {
  margin-right: 4px;
  color: #0f1642;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
}

.lesson__awardPoints_money span {
  margin-right: 8px;
}

.lesson__availableBox {
  &_unavailable {
    opacity: 0.3;
  }

  @media (min-width: tablet_large) {
    margin: -5px 0;
  }
}

.lesson__availableDate {
  font-weight: 700;

  @media (min-width: tablet_large) {
    display: block;
  }
}
</style>
