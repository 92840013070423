<template>
  <main class="main">
    <BaseBreadcrumbs :breadcrumbs="breadcrumbs" />
    <NavigatorLessonContainer />
  </main>
</template>

<script>
import NavigatorLessonContainer from "@/containers/Navigator/NavigatorLessonContainer.vue";
export default {
  name: "NavigatorLesson",
  components: {
    NavigatorLessonContainer,
  },
  data() {
    return {
      breadcrumbs: [
        { title: "Бизнес-навигатор", link: "/navigator" },
        { title: "Бизнес-квест «Старт»", link: "/navigator/course/1/quest/1" },
      ],
    };
  },
};
</script>
