<template>
  <div class="navigatorQuestInfo">
    <!-- PROGRESSBAR -->
    <div class="navigatorQuestProgressbar">
      <span class="navigatorQuestProgressbar__title">
        <Tr>Прогресс</Tr>
      </span>
      <div class="navigatorQuestProgressbar__numWrap">
        <span
          class="
            navigatorQuestProgressbar__num navigatorQuestProgressbar__num_bold
          "
        >
          {{ quest.done_user_quest_tasks_count }} </span
        >/
        <span class="navigatorQuestProgressbar__num">
          {{ quest.tasks_count }}
        </span>
      </div>
      <div class="navigatorQuestProgressbar__scale">
        <div
          :style="`width:${scaleFill}%`"
          class="navigatorQuestProgressbar__scaleFiller"
        />
      </div>
    </div>
    <!-- PROGRESSBAR ENDS -->
    <div class="navigatorQuestInfo__description">
      <div class="navigatorQuestInfo__block navigatorQuestLesson">
        <span class="navigatorQuestLesson__title">
          <Tr>Текущий урок</Tr>
        </span>
        <span class="navigatorQuestLesson__text">
          {{ questTaskName }}
        </span>
      </div>
      <div
        class="navigatorQuestInfo__block navigatorQuestDeadline"
        v-if="quest.current_quest_task && quest.current_quest_task.deadline"
      >
        <span class="navigatorQuestDeadline__title">
          <Tr>Дедлайн</Tr>
        </span>
        <span class="navigatorQuestDeadline__text">
          {{ deadline }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDateTz } from "@/utils/formatDateTz";
import { mapGetters } from "vuex";

export default {
  name: "NavigatorQuestItemProgress",
  props: {
    quest: Object,
  },
  computed: {
    ...mapGetters({
      currentLang: "currentLang",
    }),

    scaleFill() {
      return Math.floor(
        (this.quest.done_user_quest_tasks_count / this.quest.tasks_count) *
        100
      );
    },

    questTaskName() {
      const localeDescription =
        this.quest?.current_quest_task?.locales.name[this.currentLang];

      const anyDescription = () => {
        const existingDescriptions = [];

        if (this.quest && this.quest.current_quest_task && this.quest.current_quest_task.locales && this.quest.current_quest_task.locales.name) {
          Object.values(this.quest?.current_quest_task?.locales.name).map(descr => {
            if (descr) existingDescriptions.push(descr);
          });
        }

        return existingDescriptions.length > 0 ? existingDescriptions[0] : "";
      };

      return localeDescription ? localeDescription : anyDescription();
    },

    deadline() {
      return this.quest.current_quest_task && this.quest.current_quest_task.deadline ? formatDateTz(this.quest.current_quest_task.deadline, this.currentLang) : "";
    },
  },
};
</script>

<style lang="stylus" scoped>
.navigatorQuestInfo {
  margin-top: -3px;
  margin-bottom: 20px;
}

.navigatorQuestProgressbar__numWrap {
  float: right;
}

.navigatorQuestProgressbar__title, .navigatorQuestProgressbar__num, .navigatorQuestLesson__title, .navigatorQuestDeadline__title {
  font_small();
}

.navigatorQuestProgressbar__num_bold {
  font-weight: 700;
}

.navigatorQuestProgressbar__scale {
  margin-top: 8px;
  width: 100%;
  height: 20px;
  border-radius: 4px;
  background-color: #e6e7eb;
  overflow: hidden;
}

.navigatorQuestProgressbar__scaleFiller {
  height: 100%;
  background-image: linear-gradient(180deg, #fdba7a 0%, #ff9f43 100%);
}

.navigatorQuestLesson__text, .navigatorQuestDeadline__text {
  font_bold();
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.navigatorQuestInfo__description {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 16px;
}

.navigatorQuestInfo__block {
  display: inline-grid;
  grid-row-gap: 3px;
}
</style>
