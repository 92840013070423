<template>
  <div class="lessonTask">
    <div class="lessonTask__body" @click="showTask">
      <h3
        class="lessonTask__title"
        :class="{ lessonTask__title_unavailable: !taskIsAvailable }"
      >
        {{ task.title }}
      </h3>
      <div class="lessonTaskAwardBox">
        <div class="lessonTaskAward">
          <span class="lessonTaskAward__amount">{{ task.award }}</span>
          <img class="lessonTaskAward__img" src="@/assets/images/gold@2x.png" />
        </div>
      </div>
    </div>
    <navigator-lesson-test
      :questions="questions"
      v-if="task.type === 'test' && taskIsOn && taskIsAvailable"
    />
    <navigator-lesson-video
      v-else-if="task.type === 'video' && taskIsOn && taskIsAvailable"
    />
    <div
      :class="{ done: task.isDone }"
      class="lessonTask__result lessonTaskResult deadline"
    >
      <span class="result result_done" v-if="task.isDone">
        <Tr>Задание выполнено</Tr>
      </span>
      <template v-esle>
        <span class="result" v-if="taskIsAvailable">
          <Tr>Дедлайн</Tr>
          <b>{{ task.textDeadline }}</b>
        </span>
        <span class="result" v-else>
          <Tr>Сначала выполните предыдущее задание</Tr>
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import NavigatorLessonTest from "@/components/Navigator/NavigatorLessonTest.vue";
import NavigatorLessonVideo from "@/components/Navigator/NavigatorLessonVideo.vue";

export default {
  name: "NavigatorQuestLessonTask",
  data() {
    return {
      taskIsOn: false,
    };
  },
  components: {
    NavigatorLessonTest,
    NavigatorLessonVideo,
  },
  props: {
    tasks: Array,
    task: Object,
    questions: Array,
    index: Number,
  },
  methods: {
    showTask() {
      this.taskIsOn = !this.taskIsOn;
    },
  },
  computed: {
    taskIsAvailable() {
      if (this.index === 0) {
        return true;
      } else if (this.index > 0 && this.tasks[this.index - 1].isDone === true) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.lessonTask {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  border: 1px solid #e6e7eb;
  border-radius: 4px;
}

.lessonTask__body {
  display: grid;
  grid-template-columns: auto max-content;
  grid-column-gap: 20px;
  padding: 10px;
  cursor: pointer;

  @media (min-width: tablet_large) {
    padding: 16px;
  }
}

.lessonTask__title {
  font_bold();
  letter-spacing: -0.17px;

  &_unavailable {
    opacity: 0.3;
  }
}

.lessonTaskResult {
  display: block;
  padding: 8px;
  background-color: #e6e7eb;
  font_small();
  letter-spacing: -0.17px;

  @media (min-width: tablet_large) {
    padding: 8px 16px;
  }

  & b {
    font-weight: 700;
  }
}

.done {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #19c564;
  color: #fff;
}

.lessonTask__result .result, .lessonTask__result .result_done {
  display: none;
}

.deadline .resultDefault, .deadline .result_done {
  display: none;
}

.deadline .result {
  display: block;
}

.done .result, .done .resultDefault {
  display: none;
}

.done .result_done {
  display: block;
}

.lessonTaskAwardBox {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
}

.lessonTaskAward__img {
  width: 15px;
  height: 20px;
}

.lessonTaskAward__amount {
  margin-right: 8px;
  color: #0f1642;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
}
</style>
