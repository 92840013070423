<template>
  <div class="navigatorQuestDescription">
    <div class="navigatorQuestDescription__text">
      {{ description(quest) }}
    </div>
    <div
      class="navigatorQuestDescription__buttonBox"
      v-if="quest.button_data.button"
    >
      <div class="navigatorQuestDescription__priceBox">
        <div
          class="navigatorQuestDescription__price"
          v-if="quest.button_data.cost"
        >
          {{ quest.button_data.cost }} €
        </div>
        <base-link
          class="navigatorQuestDescription__button"
          :class="{
            navigatorQuestDescription__button_disabled:
              !quest.button_data.button,
          }"
          :to="`${quest.quest_card_link}`"
        >
          {{ quest.button_data.button_text }}
        </base-link>
      </div>
      <div class="navigatorQuestDescription__notification">
        {{ quest.button_data.help_text }}
      </div>
    </div>
  </div>
</template>

<script>
import bnCourseNameDescr from "@/mixins/bnCourseNameDescr.js";
import declension from "@/utils/declension";

export default {
  name: "NavigatorQuestItemDescription",
  props: {
    quest: Object,
  },

  mixins: [bnCourseNameDescr],

  computed: {
    declension() {
      return declension("день", "дней", "дня", this.quest.days);
    },
  },
};
</script>

<style lang="stylus" scoped>
.navigatorQuestDescription {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__text {
    font(main_text_color, 14px, 400, 19px);
    letter-spacing: -0.1px;
  }

  &__buttonBox {
    display: inline-grid;
    grid-auto-flow: column;
    grid-column-gap: 15px;
    align-items: self-start;
    margin-top: 21px;
    width: fit-content;
  }

  &__button {
    padding: 11px 12px;
    border: none;
    border-radius: 4px;
    background: none;
    background-image: linear-gradient(180deg, #fdba7a 0%, #ff9f43 100%);
    text-decoration: none;
    font(#FFF, 14px, 400, 18px);

    &:hover {
      cursor: pointer;
      opacity: 0.9;
    }

    &_disabled {
      pointer-events: none;
      cursor: default;

      & span {
        opacity: 0.3;
      }
    }
  }

  &__notification {
    font(main_text_color, 12px, 400, 16px);
    letter-spacing: -0.17px;

    @media (min-width: tablet_large) {
      width: 160px;
    }

    &_bold {
      font-weight: 700;
    }
  }

  &__priceBox {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 8px;
  }

  &__price {
    display: flex;
    align-items: center;
    padding: 5px;
    border: 2px solid grey_text_color;
    border-radius: 4px;
    font_bold();

    @media (min-width: tablet_large) {
      padding: 8px 12px;
      font_subtitle();
    }
  }
}
</style>
