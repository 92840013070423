<template>
  <div class="loaderBox">
    <div class="loaderIcon" v-if="0">
      <loader />
    </div>

    <template v-else>
      <ul class="lessonsList">
        <NavigatorQuestLesson
          v-for="(lesson, index) in lessons"
          :key="lesson.id"
          :lesson="lesson"
          :lessons="lessons"
          :index="index"
        />
      </ul>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NavigatorQuestLesson from "@/components/Navigator/NavigatorQuestLesson.vue";

export default {
  name: "NavigatorQuestContainer",
  components: {
    NavigatorQuestLesson,
  },
  data() {
    return {
      lessons: [
        {
          id: 1,
          title: "Как работать с контактами",
          link: "/navigator/course/1/quest/1/lesson/1",
          isDone: true,
          isDoneDate: "10 апр., 13:40",
          deadline: "2021-04-08 13:40",
          gold: 12,
          diamonds: null,
          availableDate: "2021-07-17 03:03",
        },
        {
          id: 2,
          title: "Постановка целей",
          link: "/navigator/course/1/quest/1/lesson/1",
          isDone: true,
          isDoneDate: "10 апр., 13:40",
          deadline: "2021-04-08 13:40",
          gold: 2,
          diamonds: 10,
          availableDate: "2021-07-17 03:03",
        },
        {
          id: 3,
          title: "Инструменты достижения целей",
          link: "/navigator/course/1/quest/1/lesson/1",
          isDone: false,
          isDoneDate: "10 апр., 13:40",
          deadline: "2021-04-08 13:40",
          gold: 2,
          diamonds: 10,
          availableDate: "2021-07-16 03:03",
        },
        {
          id: 4,
          title: "Синхронизация своих целей",
          link: "/navigator/course/1/quest/1/lesson/1",
          isDone: false,
          isDoneDate: "10 апр., 13:40",
          deadline: "2021-04-08 13:40",
          gold: null,
          diamonds: null,
          availableDate: "2021-07-16 03:03",
        },
        {
          id: 5,
          title: "Презентация",
          link: "/navigator/course/1/quest/1/lesson/1",
          isDone: false,
          isDoneDate: "10 апр., 13:40",
          deadline: null,
          gold: null,
          diamonds: null,
          availableDate: null,
        },
        {
          id: 6,
          title: "Твой успешный старт",
          link: "/navigator/course/1/quest/1/lesson/1",
          isDone: false,
          isDoneDate: "10 апр., 13:40",
          deadline: null,
          gold: null,
          diamonds: null,
          availableDate: "2021-07-20 03:03",
        },
      ],
    };
  },

  computed: {
    ...mapState({
      quest: state => state.navigatorQuestsItem.data,
    }),
  },
};
</script>

<style lang="stylus" scoped>
.lessonsList {
  display: grid;
  grid-row-gap: 8px;
  margin-top: 16px;
  width: 100%;
  max-width: 720px;
  counter-reset: list;
}
</style>
