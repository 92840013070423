<template>
  <div class="loaderBox">
    <div class="loaderIcon" v-if="questsPending">
      <loader />
    </div>

    <template v-else>
      <navigator-languages
        :navigatorQuestsLang="navigatorQuestsLang"
        :langs="langs"
        @chooseLang="chooseLang"
      />
      <no-content v-if="isNoContent" :noContent="noContent" class="noContent" />
      <div class="navigatorQuests">
        <navigator-quest-item
          v-for="quest in quests"
          :key="quest.id"
          :quest="quest"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

import Loader from "@/components/Loader.vue";
import NoContent from "@/components/NoContent.vue";
import NavigatorQuestItem from "@/components/Navigator/NavigatorQuestItem.vue";
import NavigatorLanguages from "@/components/Navigator/NavigatorLanguages.vue";

export default {
  name: "NavigatorCourseContainer",

  components: {
    Loader,
    NoContent,
    NavigatorQuestItem,
    NavigatorLanguages,
  },

  props: {
    courseId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      noContent: {
        noFunnels: false,
        text: "Этот раздел сейчас в разработке.",
      },
    };
  },

  computed: {
    ...mapGetters({
      quests: "navigatorCourseQuests/quests",
      langs: "supportedLangs",
    }),

    ...mapState({
      questsPending: state => state.navigatorCourseQuests.pending,
      navigatorQuestsLang: state => state.navigator.navigatorQuestsLang,
    }),

    isNoContent() {
      return this.quests?.length === 0;
    },
  },

  methods: {
    ...mapActions({
      fetchQuests: "navigatorCourseQuests/fetch",
    }),

    ...mapMutations({
      setNavigatorCoursesLang: "navigator/SET_NAVIGATOR_QUESTS_LANG",
    }),

    chooseLang(lang) {
      this.fetchQuests({ courseId: this.courseId, lang });
      this.setNavigatorCoursesLang(lang);
    },
  },
};
</script>

<style lang="stylus" scoped>
.navigatorQuests {
  display: grid;
  grid-gap: 24px 16px;
  padding-top: 3px;

  @media (min-width: tablet_large) {
    grid-template-columns: repeat(2, 456px);
  }
}

.noContent {
  max-width: 640px;
}
</style>
