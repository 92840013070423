<template>
  <div class="addonFiles">
    <h2 class="addonFiles__title">
      <Tr>Дополнительные материалы</Tr>
    </h2>
    <div class="addonFiles__box">
      <div :key="file.title" class="addonFiles__file" v-for="file in files">
        <a :href="file.link" class="addonFiles__link" download="download">
          {{ file.title }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigatorLessonAddonFile",
  components: {},
  props: {
    files: Array,
  },
};
</script>

<style lang="stylus" scoped>
.addonFiles__title {
  font_bold();
  margin-bottom: 4px;
}

.addonFiles__box {
  display: grid;
  grid-row-gap: 8px;
  margin-top: 8px;
  // width: 272px;
}

.addonFiles__file {
  display: flex;
  justify-content: space-between;
}

.addonFiles__link {
  font_regular();
  color: #3489f8;
}
</style>
