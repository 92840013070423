<template>
  <li
    :class="{
      right: this.answer.is_right && userPick !== null,
      wrong: !this.answer.is_right && userPick === answer.id,
    }"
    class="answer"
    @click="pickAnswer"
  >
    {{ answer.title }}
    <p
      class="answer_description"
      v-html="answer.explanation"
      v-if="answer.explanation"
    />
  </li>
</template>

<script>
export default {
  name: "NavigatorLessonTestAnswer",
  props: {
    answer: Object,
    userPick: [Number, null],
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    pickAnswer() {
      this.$emit("choose-answer", this.answer.id);
    },
  },
};
</script>

<style lang="stylus" scoped>
.answer {
  padding: 10px;
  border-bottom: 1px solid #e6e7eb;
  border-left: 4px solid transparent;
  font_regular();
  letter-spacing: -0.1px;
  cursor: pointer;

  @media (min-width: tablet_large) {
    padding: 11px 22px 9px 12px;
  }
}

.answer:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.answer:last-child .answer {
  border-bottom: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.right {
  border-left: 4px solid #19c564 !important;
}

.wrong {
  border-left: 4px solid #e81526 !important;

  & .answer_description {
    display: block;
  }
}

.answer_description {
  display: none;
  margin-top: 16px;
  font(main_text_color, 12px, 400, 16px);
}
</style>
