import { render, staticRenderFns } from "./UpdatedFunnelContainer.vue?vue&type=template&id=3b15f8c2&scoped=true&"
import script from "./UpdatedFunnelContainer.vue?vue&type=script&lang=js&"
export * from "./UpdatedFunnelContainer.vue?vue&type=script&lang=js&"
import style0 from "./UpdatedFunnelContainer.vue?vue&type=style&index=0&id=3b15f8c2&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b15f8c2",
  null
  
)

export default component.exports