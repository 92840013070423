<template>
  <li class="question">
    {{ question.title }}
    <ul class="answersBox">
      <NavigatorLessonTestAnswer
        v-for="answer in question.answers"
        :key="answer.title"
        :answer="answer"
        @choose-answer="pickAnswer"
        :userPick="userPick"
      />
    </ul>
  </li>
</template>

<script>
import NavigatorLessonTestAnswer from "@/components/Navigator/NavigatorLessonTestAnswer.vue";

export default {
  name: "NavigatorLessonTestQuestion",
  components: {
    NavigatorLessonTestAnswer,
  },
  props: {
    question: Object,
  },
  data() {
    return {
      userPick: null,
    };
  },
  methods: {
    pickAnswer(answerId) {
      if (this.userPick === null) {
        this.userPick = answerId;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.question {
  position: relative;
  margin-bottom: 24px;
}

.question::before {
  position: absolute;
  counter-increment: list;
  content: counter(list) '.';
  left: -15px;
}

.answersBox {
  margin-top: 16px;
  margin-left: -16px;
  border: 1px solid #e6e7eb;
  border-radius: 4px;
}
</style>
