<template>
  <div class="loaderBox">
    <div class="loaderIcon" v-if="pending">
      <loader />
    </div>

    <template v-else>
      <navigator-languages
        :navigatorQuestsLang="navigatorQuestsLang"
        :langs="langs"
        @chooseLang="chooseLang"
      />
      <no-content v-if="isNoContent" :noContent="noContent" class="noContent" />
      <div class="navigatorCourses">
        <navigator-course-item
          v-for="course in courses"
          :key="course.id"
          :course="course"
        />
        <navigator-quest-item
          v-for="quest in quests"
          :key="quest.id"
          :quest="quest"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import Loader from "@/components/Loader.vue";
import NoContent from "@/components/NoContent.vue";
import NavigatorCourseItem from "@/components/Navigator/NavigatorCourseItem.vue";
import NavigatorQuestItem from "@/components/Navigator/NavigatorQuestItem.vue";
import NavigatorLanguages from "@/components/Navigator/NavigatorLanguages.vue";

export default {
  name: "NavigatorContainer",
  components: {
    Loader,
    NoContent,
    NavigatorCourseItem,
    NavigatorQuestItem,
    NavigatorLanguages,
  },
  data() {
    return {
      noContent: {
        noFunnels: false,
        text: "Этот раздел сейчас в разработке.",
        maxWidth: '640px',
      },
    };
  },

  computed: {
    ...mapGetters({
      courses: "navigatorCourses/courses",
      quests: "navigatorQuests/quests",
      langs: "supportedLangs",
    }),
    ...mapState({
      coursesPending: state => state.navigatorCourses.pending,
      questsPending: state => state.navigatorQuests.pending,
      navigatorQuestsLang: state => state.navigator.navigatorQuestsLang,
    }),

    pending() {
      return this.coursesPending || this.questsPending;
    },

    isNoContent() {
      return this.courses?.length === 0 && this.quests?.length === 0;
    },
  },

  methods: {
    ...mapActions({
      fetchCourses: "navigatorCourses/fetch",
      fetchQuests: "navigatorQuests/fetch",
    }),
    ...mapMutations({
      setNavigatorCoursesLang: "navigator/SET_NAVIGATOR_QUESTS_LANG",
    }),

    chooseLang(lang) {
      this.fetchCourses(lang);
      this.fetchQuests(lang);
      this.setNavigatorCoursesLang(lang);
    },
  },

  mounted() {
    this.fetchCourses();
    this.fetchQuests();
  },
};
</script>

<style lang="stylus" scoped>
.navigatorCourses {
  display: grid;
  grid-gap: 16px;
  padding-top: 3px;

  @media (min-width: tablet_large) {
    grid-template-columns: repeat(2, 456px);
  }
}

.noContent {
  max-width: 640px;
}
</style>
