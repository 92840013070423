export default {
  namespaced: true,

  state: () => ({
    navigatorQuestsLang: "all",
  }),

  mutations: {
    SET_NAVIGATOR_QUESTS_LANG(state, lang) {
      state.navigatorQuestsLang = lang;
    },
  },
};
