<template>
  <base-link :to="`/navigator/course/${course.id}`">
    <navigator-item
      :image="course.image"
      :title="title(course)"
      :questIsStarted="true"
      itemType="course"
    >
      <div class="navigatorCourseDescription">
        <div class="navigatorCourseDescription__text">
          {{ description(course) }}
        </div>
      </div>
    </navigator-item>
  </base-link>
</template>

<script>
import bnCourseNameDescr from "@/mixins/bnCourseNameDescr.js";

import NavigatorItem from "@/components/Navigator/NavigatorItem.vue";
export default {
  name: "NavigatorCourseItem",

  components: {
    NavigatorItem,
  },

  mixins: [bnCourseNameDescr],

  props: {
    course: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="stylus" scoped>
.navigatorCourseDescription {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__text {
    font(main_text_color, 14px, 400, 19px);
    letter-spacing: -0.1px;
    word-break: break-word;
  }
}
</style>
