import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      currentLang: "currentLang",
    }),
  },

  methods: {
    title(item) {
      if (Object.keys(item).length > 0) {
        const localeName = item.locales.name[this.currentLang];

        return localeName ? localeName : item.name;
      }
    },

    description(item) {
      const localeDescription =
        item.locales.short_description[this.currentLang];

      const anyDescription = () => {
        const existingDescriptions = [];

        Object.values(item.locales.short_description).map(descr => {
          if (descr) {
            existingDescriptions.push(descr);
          }
        });

        return existingDescriptions.length > 0 ? existingDescriptions[0] : "";
      };

      return localeDescription ? localeDescription : anyDescription();
    },
  },
};
