<template>
  <div class="lesson">
    <div class="lesson__header">
      <span class="lesson__number"><Tr>Урок</Tr> 3</span>
      <h1 class="lesson__title">Инструменты достижения целей</h1>
    </div>
    <div class="lesson__body">
      <div class="lesson__description">
        <NavigatorLessonText />
        <div class="lesson__addonFiles">
          <NavigatorLessonAddonFile :files="files" />
        </div>
      </div>
      <div class="lesson__divider" />
      <div class="lesson__tasks">
        <NavigatorLessonTask
          :task="task"
          :tasks="tasks"
          :index="index"
          :questions="questions"
          v-for="(task, index) in tasks"
          :key="task.id"
        />
      </div>
    </div>
  </div>
</template>
<script>
import NavigatorLessonTask from "@/components/Navigator/NavigatorLessonTask.vue";
import NavigatorLessonText from "@/components/Navigator/NavigatorLessonText.vue";
import NavigatorLessonAddonFile from "@/components//Navigator/NavigatorLessonAddonFile.vue";

export default {
  name: "NavigatorLessonContainer",
  components: {
    NavigatorLessonTask,
    NavigatorLessonText,
    NavigatorLessonAddonFile,
  },
  data() {
    return {
      test: true,
      tasks: [
        {
          id: "1",
          title: "Прочитать текст и посмотреть видео",
          award: "10",
          isDone: true,
          textDeadline: "12 апр., 17:40",
          type: "video",
        },
        {
          id: "2",
          title: "Пройти тест",
          award: "12",
          isDone: false,
          textDeadline: "12 апр., 17:40",
          type: "test",
        },
        {
          id: "3",
          title: "Пройти тест",
          award: "9",
          isDone: false,
          textDeadline: "12 апр., 17:40",
          type: "test",
        },
      ],
      questions: [
        {
          id: 0,
          title: "Что такое MLMPult?",
          answers: [
            {
              id: 0,
              title: "Это домашний кинотеатр",
              is_right: true,
              explanation:
                "Конечно, вы можете познакомиться в процессе построения бизнеса в Network Marketing с больших количеством интересных людей, с которыми всегда можно пойти в кинотеатр или как-то по-другому хорошо провести время. Но основная задача платформы ‒ это все же привлечение новых людей, обучение предпринимательству и систематизация процесса взаимодействия с ними.",
            },
            {
              id: 1,
              title: "Это система для автоматизации построения сети",
              is_right: false,
              explanation:
                "Конечно, вы можете познакомиться в процессе построения бизнеса в Network Marketing с больших количеством интересных людей, с которыми всегда можно пойти в кинотеатр или как-то по-другому хорошо провести время. Но основная задача платформы ‒ это все же привлечение новых людей, обучение предпринимательству и систематизация процесса взаимодействия с ними.",
            },
            {
              id: 2,
              title: "Это система, которая вместо меня построит мне сеть",
              is_right: false,
              explanation:
                "Конечно, вы можете познакомиться в процессе построения бизнеса в Network Marketing с больших количеством интересных людей, с которыми всегда можно пойти в кинотеатр или как-то по-другому хорошо провести время. Но основная задача платформы ‒ это все же привлечение новых людей, обучение предпринимательству и систематизация процесса взаимодействия с ними.",
            },
          ],
        },
        {
          id: 1,
          title: "Какие три задачи решает MLMPult?",
          answers: [
            {
              id: 0,
              title:
                "Помогает привлечь людей, обучить их online- предпринимательству и систематизировать работу с ними",
              is_right: true,
              explanation:
                "Конечно, вы можете познакомиться в процессе построения бизнеса в Network Marketing с больших количеством интересных людей, с которыми всегда можно пойти в кинотеатр или как-то по-другому хорошо провести время. Но основная задача платформы ‒ это все же привлечение новых людей, обучение предпринимательству и систематизация процесса взаимодействия с ними.",
            },
            {
              id: 1,
              title:
                "Помогает получить скидку на попкорн, билет в кинотеатр и подругу (друга)",

              is_right: false,
              explanation:
                "Конечно, вы можете познакомиться в процессе построения бизнеса в Network Marketing с больших количеством интересных людей, с которыми всегда можно пойти в кинотеатр или как-то по-другому хорошо провести время. Но основная задача платформы ‒ это все же привлечение новых людей, обучение предпринимательству и систематизация процесса взаимодействия с ними.",
            },
          ],
        },
        {
          id: 2,
          title: "Что такое золотые монеты в MLMPult?",
          answers: [
            {
              id: 0,
              title: "Это игровые монеты, собирая которые я принимаю участие",
              is_right: true,
              explanation:
                "Конечно, вы можете познакомиться в процессе построения бизнеса в Network Marketing с больших количеством интересных людей, с которыми всегда можно пойти в кинотеатр или как-то по-другому хорошо провести время. Но основная задача платформы ‒ это все же привлечение новых людей, обучение предпринимательству и систематизация процесса взаимодействия с ними.",
            },
            {
              id: 1,
              title: "Это расчетная единица морских пиратов",
              is_right: false,
              explanation:
                "Конечно, вы можете познакомиться в процессе построения бизнеса в Network Marketing с больших количеством интересных людей, с которыми всегда можно пойти в кинотеатр или как-то по-другому хорошо провести время. Но основная задача платформы ‒ это все же привлечение новых людей, обучение предпринимательству и систематизация процесса взаимодействия с ними.",
            },
          ],
        },
        {
          id: 3,
          title: "Что такое Бизнес-навигатор?",
          answers: [
            {
              id: 0,
              title: "Это пошаговая система, выполняя рекомендации",
              is_right: true,
              explanation:
                "Конечно, вы можете познакомиться в процессе построения бизнеса в Network Marketing с больших количеством интересных людей, с которыми всегда можно пойти в кинотеатр или как-то по-другому хорошо провести время. Но основная задача платформы ‒ это все же привлечение новых людей, обучение предпринимательству и систематизация процесса взаимодействия с ними.",
            },
            {
              id: 1,
              title: "Это GPS система для автомобилей предпринимателей",
              is_right: false,
              explanation:
                "Конечно, вы можете познакомиться в процессе построения бизнеса в Network Marketing с больших количеством интересных людей, с которыми всегда можно пойти в кинотеатр или как-то по-другому хорошо провести время. Но основная задача платформы ‒ это все же привлечение новых людей, обучение предпринимательству и систематизация процесса взаимодействия с ними.",
            },
          ],
        },
      ],
      files: [
        {
          link: "@/assets/images/lesson_video.jpg",
          title: "map_1244.jpg",
          button: false,
        },
        {
          link: "#",
          title: "file_444999fff.pdf",
          button: false,
        },
      ],
    };
  },
  methods: {
    handleActiveTab(tabName) {
      this.activeTab = tabName;
    },
  },
};
</script>

<style lang="stylus" scoped>
.lesson {
  padding: 15px 8px 24px;
  width: 100%;
  max-width: 692px;
  border-top: 3px solid transparent;
  box-shadow: 0px 3px 10px #0f164214;
  border-radius: 8px;

  @media (min-width: tablet_large) {
    padding: 15px 16px 24px;
  }
}

.lesson__header {
  margin: 0px -8px;
  padding: 0px 16px;
  padding-bottom: 12px;
  border-bottom: 2px solid #e6e7eb;

  @media (min-width: tablet_large) {
    margin: 0px -16px;
  }
}

.lesson__number {
  font_small();
}

.lesson__title {
  margin-top: 4px;
  font_subtitle();
  letter-spacing: -0.1px;
}

.lesson__body {
  padding-top: 15px;
}

.lesson__addonFiles {
  margin-bottom: 24px;
}

.lesson__tasks {
  display: grid;
  grid-row-gap: 16px;
  margin-top: 16px;
}

.lesson__divider {
  margin-right: -16px;
  margin-left: -16px;
  border-bottom: 4px solid grey_text_color;
}

.lesson__task {
  padding-top: 16px;
}

.lesson__confirmation {
  width: 100%;
  max-width: 355px;
  font(main_text_color, 14px, 400, 19px);
  letter-spacing: -0.17px;
}

.lesson__submit {
  margin-top: 20px;
  padding: 11px 12px;
  border: none;
  background: none;
  background-image: linear-gradient(180deg, #fdba7a 0%, #ff9f43 100%);
  border-radius: 4px;
  font_regular();
  color: #fff;
}
</style>
