<template>
  <navigator-item
    :image="quest.image"
    :title="title(quest)"
    :languages="quest.languages"
    itemType="quest"
    :questIsStarted="questIsStarted"
  >
    <NavigatorQuestItemProgress :quest="quest" v-if="quest.user_task" />
    <NavigatorQuestItemDescription v-else :quest="quest" />
    <base-link
      :to="`/navigator/quest/${quest.id}`"
      v-if="questIsStarted"
      class="linkInProgress"
    />
  </navigator-item>
</template>

<script>
import bnCourseNameDescr from "@/mixins/bnCourseNameDescr.js";

import NavigatorItem from "@/components/Navigator/NavigatorItem.vue";
import NavigatorQuestItemProgress from "@/components/Navigator/NavigatorQuestItemProgress.vue";
import NavigatorQuestItemDescription from "@/components/Navigator/NavigatorQuestItemDescription.vue";
export default {
  name: "NavigatorQuestItem",

  components: {
    NavigatorItem,
    NavigatorQuestItemProgress,
    NavigatorQuestItemDescription,
  },

  mixins: [bnCourseNameDescr],

  props: {
    quest: Object,
  },

  computed: {
    questIsStarted() {
      return Boolean(this.quest.user_task) && Boolean(this.quest.quest_card_link);
    },
  },
};
</script>

<style lang="stylus" scoped>
.linkInProgress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
