<template>
  <ul class="navigatorLanguages">
    <li class="navigatorLanguages__item">
      <button
        :class="[
          'navigatorLanguages__btn',
          { navigatorLanguages__btn_active: navigatorQuestsLang === 'all' },
        ]"
        type="button"
        @click="chooseLang('all')"
      >
        <Tr>Все языки</Tr>
      </button>
    </li>
    <li class="navigatorLanguages__item" v-for="lang in langs" :key="lang">
      <button
        :class="[
          'navigatorLanguages__btn navigatorLanguages__btn_upperCase',
          { navigatorLanguages__btn_active: lang === navigatorQuestsLang },
        ]"
        type="button"
        @click="chooseLang(lang)"
      >
        {{ lang }}
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: "NavigatorLanguages",

  props: {
    langs: {
      type: Array,
      required: true,
    },
    navigatorQuestsLang: {
      type: String,
      required: true,
    },
  },

  methods: {
    chooseLang(lang) {
      this.$emit("chooseLang", lang);
    },
  },
};
</script>

<style lang="stylus" scoped>
.navigatorLanguages {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 12px;
  margin-bottom: 27px;
  width: fit-content;

  &__btn {
    position: relative;
    padding: 0;
    background: unset;
    border: none;
    font_regular();

    &:hover {
      opacity: 0.4;
    }

    &_upperCase {
      text-transform: uppercase;
    }

    &_active {
      position: relative;

      &::after {
        position: absolute;
        content: '';
        left: 0;
        bottom: -4px;
        width: 100%;
        height: 4px;
        background-color: grey_text_color;
      }
    }
  }
}
</style>
