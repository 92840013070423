<template>
  <main class="main">
    <base-breadcrumbs :breadcrumbs="breadcrumbs" />
    <h1 class="title">{{ courseName }}</h1>
    <NavigatorCourseContainer :courseId="courseId" />
  </main>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import bnCourseNameDescr from "@/mixins/bnCourseNameDescr.js";

import NavigatorCourseContainer from "@/containers/Navigator/NavigatorCourseContainer.vue";

export default {
  name: "NavigatorCourse",

  components: {
    NavigatorCourseContainer,
  },

  mixins: [bnCourseNameDescr],

  computed: {
    ...mapState({
      course: state => state.navigatorCourseQuests.data,
      coursesPending: state => state.navigatorCourses.pending,
    }),

    ...mapGetters({
      quests: "navigatorCourseQuests/quests",
      bnName: "user/bnName",
      courses: "navigatorCourses/courses",
    }),

    breadcrumbs() {
      return [
        { title: this.bnName, link: "/navigator" }
      ]
    },

    courseId() {
      return Number(this.$route.params.courseId);
    },

    courseName() {
      if (this.courses) {
        const currentCourse = this.courses.find(course => course.id === this.courseId);
        return currentCourse ? this.title(currentCourse) : "";
      }
      return "";
    },
  },

  methods: {
    ...mapActions({
      fetchCourses: "navigatorCourses/fetch",
      fetchQuests: "navigatorCourseQuests/fetch",
    }),
  },

  mounted() {
    if (!this.courses) {
      this.fetchCourses();
    }
    this.fetchQuests({ courseId: this.courseId });
  },
};
</script>
