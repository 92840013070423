<template>
  <ol class="questionsBox">
    <NavigatorLessonTestQuestion
      v-for="question in questions"
      :key="question.title"
      :question="question"
    />
  </ol>
</template>

<script>
import NavigatorLessonTestQuestion from "@/components/Navigator/NavigatorLessonTestQuestion.vue";

export default {
  name: "NavigatorLessonTest",
  components: {
    NavigatorLessonTestQuestion,
  },
  props: {
    questions: Array,
  },
};
</script>

<style lang="stylus" scoped>
.questionsBox {
  padding-top: 8px;
  padding-right: 8px;
  padding-left: 23px;
  width: 100%;
  max-width: 520px;
  font_bold();
  letter-spacing: -0.17px;
  counter-reset: list;

  @media (min-width: tablet_large) {
    padding-right: 16px;
    padding-left: 31px;
  }
}
</style>
