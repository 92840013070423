<template>
  <div :class="['navigatorItem', { navigatorItem_started: questIsStarted }]">
    <!-- HEADER -->
    <div
      class="navigatorItemHeader"
      :class="{ navigatorItemHeader_grid: image }"
    >
      <img
        class="navigatorItemHeader__img"
        src="@/assets/images/quest-start.png"
        v-if="image"
      />
      <div class="navigatorItemHeader__titleBox">
        <div class="navigatorItemHeader__subtitleBox">
          <span class="navigatorItemHeader__subtitle">
            <Tr v-if="itemType === 'course'">Программа обучения</Tr>
            <Tr v-else-if="itemType === 'quest'">Бизнес-квест</Tr>
          </span>
          <div
            class="navigatorItemHeader__langs"
            v-if="languages && languages.length > 0"
          >
            <span
              class="navigatorItemHeader__langsItem"
              v-for="lang in languages"
              :key="lang"
            >
              {{ lang }}
            </span>
          </div>
        </div>
        <h2 class="navigatorItemHeader__title">
          {{ title }}
        </h2>
      </div>
    </div>
    <!-- HEADER ENDS -->

    <div class="navigatorItem__body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigatorItem",
  props: {
    image: String,
    title: String,
    itemType: String,
    questIsStarted: Boolean,
    languages: Array,
  },

  methods: {
    expand() {
      console.info('expand');
    },
  },
};
</script>

<style lang="stylus" scoped>
.navigatorItemWrap {
  position: relative;
}

.navigatorItemExpandBtn {
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  margin: auto;
  width: fit-content;
}

.navigatorItem {
  position: relative;
  align-self: baseline;
  padding: 10px;
  width: 100%;
  max-width: 456px;
  border-radius: 8px;
  box-shadow: 0px 3px 10px #0f164214;

  @media (min-width: tablet_large) {
    padding: 12px 18px 20px 16px;
  }

  @media (min-width: desktop) {
    &_started {
      &:hover {
        box-shadow: 5px 8px 15px 5px rgba(15, 22, 66, 0.1);
        // transform: translate(0px, -5px);
      }
    }
  }

  &_short {
    max-height: 185px;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      display: block;
      width: 100%;
      height: 64px;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    }

    .navigatorItemHeader__title {
      max-height: 24px;
      overflow-wrap: break-word;
      white-space: nowrap;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 32px;
        height: 100%;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
      }
    }
  }
}

.navigatorItemHeader {
  position: relative;
  margin-left: -10px;
  margin-right: -10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  border-bottom: 2px solid #e6e7eb;

  @media (min-width: tablet_large) {
    margin-left: -16px;
    margin-right: -18px;
    padding-right: 18px;
    padding-bottom: 13px;
    padding-left: 16px;
  }

  &_grid {
    display: grid;
    grid-template-columns: 44px auto;
    grid-column-gap: 12px;
  }

  &__img {
    width: 44px;
    height: 44px;
  }

  &__titleBox {
    position: relative;
    display: grid;
    grid-row-gap: 3px;
  }

  &__subtitle {
    font_small();
  }

  &__subtitleBox {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 8px;
    width: fit-content;
  }

  &__langs {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 4px;
    align-items: center;
    width: fit-content;
  }

  &__langsItem {
    display: block;
    padding: 1px 2px;
    background-color: grey_text_color;
    font_small();
    text-transform: uppercase;
  }

  &__title {
    max-height: unset;
    overflow: hidden;
    overflow-wrap: break-word;
    font_bold();

    @media (min-width: tablet_large) {
      max-height: unset;
      font_subtitle();
    }
  }
}

.navigatorItem__body {
  padding-top: 16px;
  min-height: calc(100% - 60px);
}
</style>
