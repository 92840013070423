<template>
  <main class="main">
    <BaseBreadcrumbs :breadcrumbs="breadcrumbs" />
    <h1 class="title">{{ title(quest) }}</h1>
    <NavigatorQuestContainer />
  </main>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import bnCourseNameDescr from "@/mixins/bnCourseNameDescr.js";

import NavigatorQuestContainer from "@/containers/Navigator/NavigatorQuestContainer.vue";

export default {
  name: "NavigatorQuest",

  components: {
    NavigatorQuestContainer,
  },

  mixins: [bnCourseNameDescr],

  computed: {
    ...mapState({
      quest: state => state.navigatorQuestsItem.data,
    }),

    ...mapGetters({
      bnName: "user/bnName",
    }),

    breadcrumbs() {
      return [
        { title: this.bnName, link: "/navigator" },
      ];
    },

    questId() {
      return Number(this.$route.params.questId);
    },
  },

  methods: {
    ...mapActions({
      fetchQuest: "navigatorQuestsItem/fetch",
    }),
  },

  mounted() {
    this.fetchQuest(this.questId);
  },
};
</script>
